import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showStyle = true;
  title = 'IDSChatSite';

  clickedChatImage() {
    //console.log("Clicked!");
    if(!this.showStyle) {
      return "none";
    } else {
      return "";
    }
    
  }
}

