import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Views:
import { HomeComponent } from './views/home/home.component';
import { AccountingComponent } from './views/accounting/accounting.component';
import { OperationsComponent } from './views/operations/operations.component';
import { PrivacyComponent } from './views/privacy/privacy.component';
import { TermsComponent } from './views/terms/terms.component';


const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'home', component: HomeComponent},
  { path: 'accounting', component: AccountingComponent},
  { path: 'operations', component: OperationsComponent},
  { path: 'privacy', component: PrivacyComponent},
  { path: 'terms', component: TermsComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
